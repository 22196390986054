import React, { Component, useEffect } from 'react'
import Dashboard from './Pages/Dashboard/Dashboard';
import Login from './Pages/Login';
import Register from './Pages/Register'
import SignUp from './Pages/SignUp'
import ForgotPassword from './Pages/ForgotPassword'
import ResetPassword from './Pages/ResetPassword'
import ChangePassword from './Pages/ChangePassword'
import { BrowserRouter, Route } from "react-router-dom";
import { Redirect } from 'react-router-dom'
import News from './Pages/News'
import NewsAdd from './Pages/NewsAdd';
import NewsUploadImage1 from './Pages/NewsUploadImage1'
import NewsUploadThumb1 from './Pages/NewsUploadThumb1'
import Tournament from './Pages/Tournament';
import TournamentAdd from './Pages/TournamentAdd';
import ContactUs from './Pages/ContactUs';
import Invoice from './Order/Invoice'
import Config from './Constants';
import Customer from './Pages/Calibration/Customer';
import CustomerAdd from './Pages/Calibration/Customer_Add';
import MasterEquipment from './Pages/Calibration/MasterEquipment';
import MasterEquipmentAdd from './Pages/Calibration/MasterEquipment_Add';
import MasterEquipmentUploadImage1 from './Pages/MasterEquipmentUploadImage1'
import MasterEquipmentUploadThumb1 from './Pages/MasterEquipmentUploadThumb1'
import Equipment from './Pages/Calibration/Equipment';
import EquipmentAdd from './Pages/Calibration/Equipment_Add';
import EquipmentPsv from './Pages/Calibration/EquipmentPsv';
import EquipmentPsvAdd from './Pages/Calibration/EquipmentPsv_Add';
import EquipmentPsvBI from './Pages/Calibration/EquipmentPsvBI';
import EquipmentPsvBIAdd from './Pages/Calibration/EquipmentPsvBI_Add';

import CscCert from './Pages/Calibration/CscCert';
import CscCert_Add from './Pages/Calibration/CscCert_Add';

import EquipmentCsc from './Pages/Calibration/EquipmentCsc';
import EquipmentCscAdd from './Pages/Calibration/EquipmentCsc_Add';

import EquipmentUploadImage1 from './Pages/EquipmentUploadImage1'
import EquipmentUploadThumb1 from './Pages/EquipmentUploadThumb1'
//import Doc from './Pages/Doc/Doc'
//import Doc_Add from './Pages/Doc/Doc_Add';
import SideBar from './Navigation/SideBar'
//import Town_Transaction_Unit from './Pages/Town/Town_Transaction_Unit';
import { matchPath } from "react-router";
//import Town_Transaction_Unit_Add from './Pages/Town/Town_Transaction_Unit_Add';
import GoogleCallBack from './Pages/Login/GoogleCallBack';
import MatchUser from './Pages/Matches/MatchUser';
import MatchOrderInsert from './Pages/Matches/MatchOrderInsert'
import CalibrationType from './Pages/Calibration/CalibrationType';
import ExhibitionForm from './Pages/Calibration/ExhibitionForm';
import CalibrationReport_MasterEquipment from './Pages/Calibration/CalibrationReport_MasterEquipment';
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import MatchOrderAdmin from './Pages/MatchOrderAdmin';

import CertificateOfCalibration from './Pages/Calibration/CertificateOfCalibration'
import CertificateOfCalibration_Add from './Pages/Calibration/CertificateOfCalibration_Add';

import CalibrationResultF0101 from './Pages/CalibrationResult/01Pressure/CalibrationResultF0101';
import CalibrationResultF0102 from './Pages/CalibrationResult/01Pressure/CalibrationResultF0102';
import CalibrationResultF0103 from './Pages/CalibrationResult/01Pressure/CalibrationResultF0103';
import CalibrationResultF0104 from './Pages/CalibrationResult/01Pressure/CalibrationResultF0104';
import CalibrationResultF0105 from './Pages/CalibrationResult/01Pressure/CalibrationResultF0105';

import CalibrationResultF0201 from './Pages/CalibrationResult/02Temperature/CalibrationResultF0201';
import CalibrationResultF0202 from './Pages/CalibrationResult/02Temperature/CalibrationResultF0202';
import CalibrationResultF0203 from './Pages/CalibrationResult/02Temperature/CalibrationResultF0203';
import CalibrationResultF0204 from './Pages/CalibrationResult/02Temperature/CalibrationResultF0204';
import CalibrationResultF0205 from './Pages/CalibrationResult/02Temperature/CalibrationResultF0205';
import CalibrationResultF0206 from './Pages/CalibrationResult/02Temperature/CalibrationResultF0206';
import CalibrationResultF0207 from './Pages/CalibrationResult/02Temperature/CalibrationResultF0207';
import CalibrationResultF0208 from './Pages/CalibrationResult/02Temperature/CalibrationResultF0208';
import CalibrationResultF0209 from './Pages/CalibrationResult/02Temperature/CalibrationResultF0209';

import CalibrationResultEN0096 from './Pages/CalibrationResult/09Electric/CalibrationResultEN0096';
import CalibrationResultEN0098 from './Pages/CalibrationResult/09Electric/CalibrationResultEN0098';

import CalibrationResultEN0099 from './Pages/CalibrationResult/09Electric/CalibrationResultEN0099';
import CalibrationResultEN0121 from './Pages/CalibrationResult/09Electric/CalibrationResultEN0121';

import CalibrationResultF0302 from './Pages/CalibrationResult/09Electric/CalibrationResultF0302';
import CalibrationResultF0303 from './Pages/CalibrationResult/09Electric/CalibrationResultF0303';
import CalibrationResultF0304 from './Pages/CalibrationResult/09Electric/CalibrationResultF0304';
import CalibrationResultF0305 from './Pages/CalibrationResult/09Electric/CalibrationResultF0305';
import CalibrationResultF0306 from './Pages/CalibrationResult/09Electric/CalibrationResultF0306';
import CalibrationResultF0307 from './Pages/CalibrationResult/09Electric/CalibrationResultF0307';
import CalibrationResultF0308 from './Pages/CalibrationResult/09Electric/CalibrationResultF0308';
import CalibrationResultF0309 from './Pages/CalibrationResult/09Electric/CalibrationResultF0309';
import CalibrationResultF0310 from './Pages/CalibrationResult/09Electric/CalibrationResultF0309';

import CalibrationResultF0401 from './Pages/CalibrationResult/04Chemical/CalibrationResultF0401';
import CalibrationResultF0402 from './Pages/CalibrationResult/04Chemical/CalibrationResultF0402';
import CalibrationResultF0403 from './Pages/CalibrationResult/04Chemical/CalibrationResultF0403';
import CalibrationResultF0404 from './Pages/CalibrationResult/04Chemical/CalibrationResultF0404';
import CalibrationResultF0405 from './Pages/CalibrationResult/04Chemical/CalibrationResultF0405';
import CalibrationResultF0406 from './Pages/CalibrationResult/04Chemical/CalibrationResultF0406';
import CalibrationResultF0407 from './Pages/CalibrationResult/04Chemical/CalibrationResultF0407';
import CalibrationResultF0408 from './Pages/CalibrationResult/04Chemical/CalibrationResultF0408';
import CalibrationResultF0409 from './Pages/CalibrationResult/04Chemical/CalibrationResultF0409';
import CalibrationResultF0410 from './Pages/CalibrationResult/04Chemical/CalibrationResultF0410';
import CalibrationResultF0411 from './Pages/CalibrationResult/04Chemical/CalibrationResultF0411';
import CalibrationResultF0412 from './Pages/CalibrationResult/04Chemical/CalibrationResultF0412';

import CalibrationResultEN0139 from './Pages/CalibrationResult/03Dimension/CalibrationResultEN0139';
// import CalibrationResultF0502 from './Pages/CalibrationResult/05Dimension/CalibrationResultF0502';

import CalibrationResultF0601 from './Pages/CalibrationResult/06Mass/CalibrationResultF0601';
import CalibrationResultF0602 from './Pages/CalibrationResult/06Mass/CalibrationResultF0602';
import CalibrationResultF0603 from './Pages/CalibrationResult/06Mass/CalibrationResultF0603';
import CalibrationResultF0604 from './Pages/CalibrationResult/06Mass/CalibrationResultF0604';
import CalibrationResultPsv from './Pages/CalibrationResult/18PSV/CalibrationResultPsv';
import CertOfCalPics from './Pages/Calibration/CertOfCalPics';

import CalibrationResult_Add from './Pages/Calibration/CalibrationResult_Add';
import TopBar from './Navigation/TopBar'
import UnitOfMeasurment from './Pages/Calibration/UnitOfMeasurment';
import ReportTemplate from './Pages/Calibration/ReportTemplate';
import CalibrationProcedure from './Pages/Calibration/CalibrationProcedure';

import Mut from './Pages/FMC/Mut';
import Mut_Add from './Pages/FMC/Mut_Add';
import CertOfMut from './Pages/FMC/CertOfMut'
import CertOfMut_Add from './Pages/FMC/CertOfMut_Add'
import CertOfCalPSV from './Pages/Calibration/CertOfCalPSV';
import CertOfCalPsv_Add from './Pages/Calibration/CertOfCalPsv_Add';
import RtCert_Add from './Pages/RT/RtCert_Add';
import RtCert from './Pages/RT/RtCert';
import RtResult from './Pages/RT/RtResult';
import RtProject from './Pages/RT/RtProject';
import RtRequest from './Pages/RT/RtRequest';
import CertOfCal from './Pages/Calibration/CertOfCal';
import RtRequestBacklog from './Pages/RT/RtRequestBacklog';

const Layout = (props) => {

  //const [user] = useAuthState(getAuth());

  document.body.classList.add('bg-gray-200');
  document.body.classList.add('g-sidenav-show');

  const auth = getAuth();

  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    window.initializeDashboard();
  });

  const loggedIn = () => {
    return false;
  }

  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  }

  const requireAuth = (nextState, replace) => {
    if (!this.loggedIn()) {
      replace({
        pathname: 'Login'
      })
    }
  }

  const isRegisterPath = matchPath(window.location.pathname, {
    path: "/Register",
    exact: true,
    strict: false
  });

  const isSignUpPath = matchPath(window.location.pathname, {
    path: "/SignUp",
    exact: true,
    strict: false
  });

  if (isRegisterPath)
    return <Register />;

  if (isSignUpPath)
    return <SignUp />;

  const isForgotPassword = matchPath(window.location.pathname, {
    path: "/ForgotPassword",
    exact: true,
    strict: false
  });

  if (isForgotPassword)
    return <ForgotPassword />;

  const isResetPassword = matchPath(window.location.pathname, {
    path: "/ResetPassword",
    exact: true,
    strict: false
  });

  if (isResetPassword)
    return <ResetPassword />;

  const isGoogleCallBack = matchPath(window.location.pathname, {
    path: "/GoogleCallBack",
    exact: true,
    strict: false
  });

  if (isGoogleCallBack)
    return <GoogleCallBack />;

  const isLoginPath = matchPath(window.location.pathname, {
    path: "/Login",
    exact: true,
    strict: false
  });

  if (loading) {
    return (
      <div>
        <p>Initialising User...</p>
      </div>)
  }

  if (!user) {
    //window.localStorage.setItem('accessToken','')
    return <Login />;
  }

  if (!user && isLoginPath)
    return <Login />;

  if (isLoginPath)
    return <Login />;

  return (

    <>

      <SideBar user={user} ></SideBar>

      <Route exact path="/" component={() => (<Dashboard user={user} />)} onEnter={requireAuth} />
      <Route exact path="/Tournament" component={Tournament} onEnter={requireAuth} />
      <Route exact path="/Tournament/Add" component={TournamentAdd} onEnter={requireAuth} />
      <Route exact path="/Tournament/Edit/:Id" component={TournamentAdd} onEnter={requireAuth} />
      <Route exact path="/News" component={News} onEnter={requireAuth} />
      <Route exact path="/News/Add" component={NewsAdd} onEnter={requireAuth} />
      <Route exact path="/News/Edit/:Id" component={NewsAdd} onEnter={requireAuth} />
      <Route exact path="/News/UploadImage1/:Id" component={NewsUploadImage1} onEnter={requireAuth} />
      <Route exact path="/News/UploadThumb1/:Id" component={NewsUploadThumb1} onEnter={requireAuth} />
      <Route exact path="/ContactUs" component={ContactUs} onEnter={requireAuth} />
      <Route path="/MatchOrderAdmin/:matchId" component={MatchOrderAdmin} onEnter={requireAuth} />
      <Route exact path="/Invoice" component={Invoice} onEnter={requireAuth} />
      <Route exact path="/Customer" component={() => <Customer user={user} />} />
      <Route exact path="/Customer/Add" component={CustomerAdd} onEnter={requireAuth} />
      <Route exact path="/Customer/Edit/:Id" component={CustomerAdd} nEnter={requireAuth} />
      <Route exact path="/Equipment" component={() => <Equipment user={user} />} />
      <Route exact path="/EquipmentPsv" component={() => <EquipmentPsv user={user} />} />
      <Route exact path="/Equipment/Add" component={EquipmentAdd} onEnter={requireAuth} />
      <Route exact path="/Equipment/Edit/:id" component={EquipmentAdd} />
      <Route exact path="/EquipmentPsv/Add" component={EquipmentPsvAdd} onEnter={requireAuth} />
      <Route exact path="/EquipmentPsv/Edit/:id" component={EquipmentPsvAdd} onEnter={requireAuth} />

      <Route exact path="/EquipmentCsc" component={() => <EquipmentCsc user={user} />} />
      <Route exact path="/EquipmentCsc/Add" component={EquipmentCscAdd} onEnter={requireAuth} />
      <Route exact path="/EquipmentCsc/Edit/:id" component={EquipmentCscAdd} onEnter={requireAuth} /> 
      <Route exact path="/CscCert" component={() => <CscCert user={user} />} onEnter={requireAuth} />
      <Route exact path="/CscCert/Add/&equipmentRef=:equipmentRef" component={CscCert_Add} />
      <Route exact path="/CscCert/Edit/:id" component={CscCert_Add} />
            
      <Route exact path="/EquipmentPsvBI" component={() => <EquipmentPsvBI user={user} />} />
      <Route exact path="/EquipmentPsvBI/Add" component={EquipmentPsvBIAdd} onEnter={requireAuth} />
      <Route exact path="/EquipmentPsvBI/Edit/:id" component={EquipmentPsvBIAdd} />



      <Route exact path="/Equipment/UploadImage1/:Id" component={EquipmentUploadImage1} onEnter={requireAuth} />
      <Route exact path="/Equipment/UploadThumb1/:Id" component={EquipmentUploadThumb1} onEnter={requireAuth} />
      <Route exact path="/CalibrationType" component={CalibrationType} onEnter={requireAuth} />
      {/* <Route exact path="/Company" component={() => (<Company user={user} />)} onEnter={requireAuth} />
      <Route exact path="/Lang" component={() => (<Lang user={user} />)} onEnter={requireAuth} />
      <Route exact path="/Division" component={() => (<Division user={user} />)} onEnter={requireAuth} />
      <Route exact path="/DocType" component={() => (<DocType user={user} />)} onEnter={requireAuth} />
      <Route exact path="/Doc" component={() => (<Doc user={user} />)} />
      <Route exact path="/Doc/Add/" component={() => (<Doc_Add user={user} />)} />
      <Route exact path="/Doc/Edit/:Id" component={Doc_Add} OnEnter={requireAuth} /> */}
      <Route exact path="/CalibrationReport_MasterEquipment/:certificateOfCalibrationRef"
        component={CalibrationReport_MasterEquipment} onEnter={requireAuth} />
      <Route exact path="/CalibrationResult/Add/&certificateOfCalibrationRef=:certificateOfCalibrationRef"
        component={CalibrationResult_Add} onEnter={requireAuth} />
      <Route exact path="/ReportTemplate" component={ReportTemplate} onEnter={requireAuth} />
      <Route exact path="/CalibrationProcedure" component={CalibrationProcedure} onEnter={requireAuth} />
      <Route exact path="/ChangePassword" component={ChangePassword} onEnter={requireAuth} />
      <Route exact path="/MatchUser" component={MatchUser} onEnter={requireAuth} />
      <Route exact path="/MatchOrder/Add/&matchRef=:matchRef" component={MatchOrderInsert} onEnter={requireAuth} />
      <Route exact path="/ContactUs" component={ContactUs} onEnter={requireAuth} />
      <Route path="/MatchOrderAdmin/:matchId" component={MatchOrderAdmin} onEnter={requireAuth} />
      <Route exact path="/Invoice" component={Invoice} onEnter={requireAuth} />
      <Route exact path="/MasterEquipment" component={() => <MasterEquipment user={user} />} />
      <Route exact path="/MasterEquipment/Add" component={MasterEquipmentAdd} onEnter={requireAuth} />
      <Route exact path="/MasterEquipment/Edit/:Id" component={MasterEquipmentAdd} onEnter={requireAuth} />
      <Route exact path="/MasterEquipment/UploadImage1/:Id" component={MasterEquipmentUploadImage1} onEnter={requireAuth} />
      <Route exact path="/MasterEquipment/UploadThumb1/:Id" component={MasterEquipmentUploadThumb1} onEnter={requireAuth} />
      <Route exact path="/CalibrationType" component={CalibrationType} onEnter={requireAuth} />
      <Route exact path="/UnitOfMeasurment" component={UnitOfMeasurment} onEnter={requireAuth} />

      {/* <Route exact path="/CertificateOfCalibration" component={() => <CertificateOfCalibration user={user} />} onEnter={requireAuth} /> */}
      <Route exact path="/CertificateOfCalibration" component={() => <CertOfCal user={user} />} />
      <Route exact path="/CertOfCalPsv" component={() => <CertOfCalPSV user={user} />} onEnter={requireAuth} />
      <Route exact path="/CertOfCalPsv/Add/&equipmentRef=:equipmentRef" component={CertOfCalPsv_Add} />
      <Route exact path="/CertOfCalPsv/Edit/:id" component={CertOfCalPsv_Add} />     

          
      <Route exact path="/CertificateOfCalibration/Add/&equipmentRef=:equipmentRef" component={CertificateOfCalibration_Add} onEnter={requireAuth} />
      <Route exact path="/CertificateOfCalibration/Edit/:id" component={CertificateOfCalibration_Add} nEnter={requireAuth} />

      <Route exact path="/CalibrationResultF0101/:entityRef" component={CalibrationResultF0101} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0102/:entityRef" component={CalibrationResultF0102} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0103/:entityRef" component={CalibrationResultF0103} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0104/:entityRef" component={CalibrationResultF0104} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0105/:entityRef" component={CalibrationResultF0105} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0201/:entityRef" component={CalibrationResultF0201} onEnter={requireAuth} />

      <Route exact path="/CalibrationResultF0202/:entityRef" component={CalibrationResultF0202} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0203/:entityRef" component={CalibrationResultF0203} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0204/:entityRef" component={CalibrationResultF0204} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0205/:entityRef" component={CalibrationResultF0205} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0206/:entityRef" component={CalibrationResultF0206} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0207/:entityRef" component={CalibrationResultF0207} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0208/:entityRef" component={CalibrationResultF0208} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0209/:entityRef" component={CalibrationResultF0209} onEnter={requireAuth} />

      {/* <Route exact path="/CalibrationResultF0301/:entityRef" component={CalibrationResultF0301} onEnter={requireAuth} /> */}
      <Route exact path="/CalibrationResultEN0096/:entityRef" component={CalibrationResultEN0096} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultEN0098/:entityRef" component={CalibrationResultEN0098} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultEN0099/:entityRef" component={CalibrationResultEN0099} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultEN0121/:entityRef" component={CalibrationResultEN0121} onEnter={requireAuth} />

      <Route exact path="/CalibrationResultF0302/:entityRef" component={CalibrationResultF0302} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0303/:entityRef" component={CalibrationResultF0303} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0304/:entityRef" component={CalibrationResultF0304} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0305/:entityRef" component={CalibrationResultF0305} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0306/:entityRef" component={CalibrationResultF0306} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0307/:entityRef" component={CalibrationResultF0307} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0308/:entityRef" component={CalibrationResultF0308} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0309/:entityRef" component={CalibrationResultF0309} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0310/:entityRef" component={CalibrationResultF0310} onEnter={requireAuth} />

      <Route exact path="/CalibrationResultF0401/:entityRef" component={CalibrationResultF0401} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0402/:entityRef" component={CalibrationResultF0402} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0403/:entityRef" component={CalibrationResultF0403} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0404/:entityRef" component={CalibrationResultF0404} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0405/:entityRef" component={CalibrationResultF0405} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0406/:entityRef" component={CalibrationResultF0406} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0407/:entityRef" component={CalibrationResultF0407} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0408/:entityRef" component={CalibrationResultF0408} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0409/:entityRef" component={CalibrationResultF0409} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0410/:entityRef" component={CalibrationResultF0410} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0411/:entityRef" component={CalibrationResultF0411} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0412/:entityRef" component={CalibrationResultF0412} onEnter={requireAuth} />

      {/* <Route exact path="/CalibrationResultF0501/:entityRef" component={CalibrationResultF0501} onEnter={requireAuth} /> */}
      <Route exact path="/CalibrationResultEN0139/:entityRef" component={CalibrationResultEN0139} onEnter={requireAuth} /> 
      {/* <Route exact path="/CalibrationResultF0502/:entityRef" component={CalibrationResultF0502} onEnter={requireAuth} /> */}

      <Route exact path="/CalibrationResultF0601/:entityRef" component={CalibrationResultF0601} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0602/:entityRef" component={CalibrationResultF0602} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0603/:entityRef" component={CalibrationResultF0603} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultF0604/:entityRef" component={CalibrationResultF0604} onEnter={requireAuth} />
      <Route exact path="/CalibrationResultPsv/:entityRef" component={CalibrationResultPsv} />

      <Route exact path="/CertOfCalPics/:entityRef" component={() => <CertOfCalPics user={user} />} />

      <Route exact path="/ExhibitionForm" component={ExhibitionForm} onEnter={requireAuth} />
      <Route exact path="/CalibrationResult/Add/&certificateOfCalibrationRef=:certificateOfCalibrationRef"
        component={CalibrationResult_Add} onEnter={requireAuth} />
      <Route exact path="/ReportTemplate" component={ReportTemplate} onEnter={requireAuth} />
      <Route exact path="/CalibrationProcedure" component={CalibrationProcedure} onEnter={requireAuth} />

      {/* <Route exact path="/Town_Unit/:buildingRef" component={Town_Unit} onEnter={requireAuth} />
                  <Route exact path="/Town_Unit/Add/&buildingRef=:buildingRef" component={Town_Unit_Add} onEnter={requireAuth} />
                  <Route exact path="/Town_Unit/Edit/:Id&buildingRef=:buildingRef" component={Town_Unit_Add} onEnter={requireAuth} />
                  <Route exact path="/Town_Transaction_Unit/:unitRef" component={Town_Transaction_Unit} onEnter={requireAuth} />
                  <Route exact path="/Town_Transaction_Unit/Add/&unitRef=:unitRef" component={Town_Transaction_Unit_Add} onEnter={requireAuth} />
                  <Route exact path="/Town_Transaction_Unit/Edit/:Id&unitRef=:unitRef" component={Town_Transaction_Unit_Add} onEnter={requireAuth} />
    <Route exact path="/Town_ChargeType" component={Town_ChargeType} onEnter={requireAuth} /> */}
      <Route exact path="/ChangePassword" component={ChangePassword} onEnter={requireAuth} />
      <Route exact path="/MatchUser" component={MatchUser} onEnter={requireAuth} />
      <Route exact path="/MatchOrder/Add/&matchRef=:matchRef" component={MatchOrderInsert} onEnter={requireAuth} />

      <Route exact path="/Mut" component={() => <Mut user={user} />} />
      <Route exact path="/Mut/Add" component={Mut_Add} onEnter={requireAuth} />
      <Route exact path="/Mut/Edit/:Id" component={Mut_Add} onEnter={requireAuth} />

      <Route exact path="/CertOfMut" component={() => <CertOfMut user={user} />} onEnter={requireAuth} />
      <Route exact path="/CertOfMut/Add/&mutRef=:mutRef" component={CertOfMut_Add} onEnter={requireAuth} />
      <Route exact path="/CertOfMutEdit/:Id" component={CertOfMut_Add} nEnter={requireAuth} />


      <Route exact path="/RtCert" component={() => <RtCert user={user} />} onEnter={requireAuth} />
      <Route exact path="/RtCert/Add/" component={RtCert_Add} />
      <Route exact path="/RtCert/Edit/:id" component={RtCert_Add} />
      <Route exact path="/RtResult/:entityRef" component={RtResult} />

      <Route exact path="/RtProject" component={() => <RtProject user={user} />} />
      <Route exact path="/RtRequest" component={() => <RtRequest user={user} />} />
      <Route exact path="/RtRequestBacklog" component={() => <RtRequestBacklog user={user} />} />



    </>
  )

}

export default Layout;